
export default {
	name: "craManagement",
	data() {
		return {
			httpHeader: {
				token: this.getCookie("token")
			},
			sex1: "girl",
			newEdit: false,
			removeAlert: false,
			list: "",
			editId: "",
			name: "",
			sex: "2",
			years: "1990-01-01",
			education: "未知",
			major: "未知",
			employmentTime: "",
			idNumber: "",
			phone: '', //手机号
			phone1: '', //手机号编辑的时候用于对比，这是改变之前的电话
			man: false,
			woman: false,
			editRoNew: "",
			headImg: "",
			removeId: "",
			removeItemIndex: "",
			total: 0,
			pageSize: 0,
			currentPage: 1,
			headImageUrl: "",
			dufImg: "",
			upExcelUrl: XyhConf.HglSHost + "/pc/uplaodExcel",
			Imgurl: XyhConf.HglSHost + "/pc/uploadFile",
			presence: false, //身份id不重复false/重复true
			todayTime: "", //当天的日期
			isRepeat: '', //手机号验证重复
			hospitalArr: [], //机构
			instId: '', //机构id
			restaurants: [],
			timeout: null, //时间
			uploadToken: {},
			isClick: true, //防止连续添加
		};
	},
	created() {
		this.token = this.getCookie("token");
		this.uploadToken = {
			token: this.token
		};
		this.instId = this.getCookie('instId');
		this.todayTime = this.curentTime()
		this.getCrcMang();
	},

	methods: {
		curentTime(data) {
			var now = new Date(data);
			var year = now.getFullYear(); //年
			var month = now.getMonth() + 1; //月
			var day = now.getDate(); //日

			var hh = now.getHours(); //时
			var mm = now.getMinutes(); //分

			var ddate = year + "-";

			if (month < 10) ddate += "0";

			ddate += month + "-";

			if (day < 10) ddate += "0";

			ddate += day;
			return ddate;
		},

		getCrcMang() {
			var _this = this;
			_this.$http
				.post(
					_this.craUrl + "/getAllCRAByEnterprise", {
					page: _this.currentPage
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response.data)
						_this.list = response.data.data.records;
						_this.total = response.data.data.total;
						_this.pageSize = response.data.data.size;
						_this.currentPage = response.data.data.current;
						for (var i = 0; i < _this.list.length; i++) {
							if (!_this.list[i].headImg) {
								_this.list[i].headImg = "https://qinghua-1256157065.cos.ap-beijing.myqcloud.com/5749829dc5c04cc99e2cd984e7f5bbf4_head.png"
							}
						}
					} else if (response.data.code == 401) {
						_this.$router.push('/')
					}
				});
		},
		//关闭弹框
		closeAlter() {
			this.newEdit = false;
		},
		//点击添加
		newHandel() {
			var datetime = new Date();
			var year = datetime.getFullYear();
			var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
			var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
			var _this = this;
			this.editId = '';
			this.newEdit = true;
			this.editRoNew = 1;
			this.name = "";
			this.years = "1990-01-01";
			this.education = "未知";
			this.major = "未知";
			this.employmentTime = year + '-' + month + '-' + date;
			this.phone = "";
			this.headImageUrl =
				"https://qinghua-1256157065.cos.ap-beijing.myqcloud.com/5749829dc5c04cc99e2cd984e7f5bbf4_head.png";
			if (_this.sex == 1) {
				_this.man = true;
				_this.woman = false;
			} else if (_this.sex == 2) {
				_this.woman = true;
				_this.man = false;
			}
			_this.$http
				.post(
					"/API/GLS/institutionList", {}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						_this.hospitalArr = response.data.data;
					}
				})
		},
		//编辑
		edit(id) {
			var _this = this;
			this.editId = id;
			_this.$http
				.post(
					_this.craUrl + "/getCRAInfo", {
					craId: id
				}, {
					headers: {
						token: _this.token
					}
				}
				)
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.name = response.data.data.name;
						_this.sex = response.data.data.sex;
						_this.years = response.data.data.birthday;
						_this.education = response.data.data.education;
						_this.major = response.data.data.major;
						_this.employmentTime = response.data.data.employmentTime;
						//						console.log(response.data.data.employmentTime)
						_this.phone = response.data.data.phone;
						_this.phone1 = response.data.data.phone;
						_this.headImageUrl = response.data.data.headImg;
						if (_this.sex == 1) {
							_this.man = true;
							_this.woman = false;
						} else {
							_this.woman = true;
							_this.man = false;
						}
						if (!_this.headImageUrl) {
							_this.headImageUrl = "https://qinghua-1256157065.cos.ap-beijing.myqcloud.com/5749829dc5c04cc99e2cd984e7f5bbf4_head.png"
						}
					}
				});
			this.newEdit = true;
			this.editRoNew = 2;
		},
		handleAvatarSuccess(res, file) {
			if (res.code == 200) {
				//					console.log(res.data)
				this.headImageUrl = res.data.name;
			}
			//		        console.log(this.headImageUrl)
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === "image/jpeg" || "image/png";
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isJPG) {
				this.$message.error("上传头像图片只能是 JPG 格式!");
			}
			if (!isLt2M) {
				this.$message.error("上传头像图片大小不能超过 2MB!");
			}
			return isJPG && isLt2M;
		},
		sexfn(sex) {
			if (sex == "boy") {
				this.sex1 = sex;
				this.sex = 1;
				this.man = true;
				this.woman = false;
			} else if (sex == "girl") {
				this.sex1 = sex;
				this.sex = 2;
				this.man = false;
				this.woman = true;
			}
		},
		changePhone() {
			var _this = this;
			if (_this.phone1 != _this.phone) {
				_this.$http.post(
					_this.craUrl + "/numberVerification", {
					phoneNumber: _this.phone
				}, {
					headers: {
						token: _this.token
					}
				}
				)
					.then(function (response) {
						if (response.data.code == 200) {
							_this.isRepeat = response.data.data.isRepeat;
							if (_this.isRepeat) {
								_this.defeated("该手机号码格式不正确或已存在");
							}
						}
					});
			}
		},
		querySearch(queryString, cb) {
			var restaurants = this.restaurants;
			var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
			// 调用 callback 返回建议列表的数据
			cb(results);
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		//点击弹框确定
		submitAlter() {
			var _this = this;
			//				console.log(_this.name)
			//				console.log(_this.sex)
			//				console.log(_this.education)
			//				console.log(_this.major)
			//				console.log(_this.employmentTime)
			//				console.log(_this.phone)
			//				console.log(_this.years)
			//				console.log(_this.major)
			if (_this.isClick) {
				_this.isClick = false;
				if (
					_this.name && (_this.sex == '1' || _this.sex == '2') &&
					_this.education &&
					_this.major &&
					_this.employmentTime &&
					_this.phone &&
					_this.years &&
					_this.major) {
					if (!_this.presence) {
						//							console.log('go')
						//手机号验证长度
						if (_this.phone.length == 11) {
							if (_this.isRepeat) {
								_this.defeated("该手机号码格式不正确或已存在");
								_this.isClick = true;
							} else {
								_this.$http.post(_this.craUrl + "/addOrUpdateCra", {
									craId: _this.editId,
									name: _this.name,
									headImg: _this.headImageUrl,
									sex: _this.sex,
									phone: _this.phone,
									birthday: _this.curentTime(_this.years),
									education: _this.education,
									major: _this.major,
									employmentTime: _this.curentTime(_this.employmentTime),
								}, {
									headers: {
										token: _this.token
									}
								})
									.then(function (response) {
										if (response.data.code == 200) {
											_this.getCrcMang();
											_this.newEdit = false;
											_this.isClick = true;
										}
									});

							}
						} else {
							_this.defeated("请输入11位手机号码");
							setTimeout(function () {
								_this.isClick = true;
							}, 1000)
						}
					} else {
						_this.defeated("该身份证号码已存在");
						setTimeout(function () {
							_this.isClick = true;
						}, 1000)
					}
				} else {
					_this.defeated("请填写完整信息");
					setTimeout(function () {
						_this.isClick = true;
					}, 1000)
				}
			}
		},
		//删除信息
		delListItem(id, index) {
			var _this = this;
			this.removeId = id;
			this.removeItemIndex = index;
			this.removeAlert = true;
		},
		yes() {
			var _this = this;
			this.removeAlert = false;
			_this.$http.post(_this.craUrl + "/deleteCra", {
				craId: _this.removeId
			}, {
				headers: {
					token: _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.list.splice(_this.removeItemIndex, 1);
					}
				});
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage;
			this.getCrcMang();
		},
		no() {
			this.removeItemIndex = "";
			this.removeAlert = false;
		},
		toPar(id) {
			this.$store.commit("getId", id);
			this.$router.push({
				name: "CraDetails",
				params: {
					id: id
				}
			});
		},
		//模板下载
		templateDownLoad() {
			var _this = this;
			_this.$http.get(_this.craUrl + '/templateCRADown', {
				responseType: 'blob'
			}, {}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					let filename = "CRA模板.xlsx";
					_this.fileDownload(response.data, filename);
				})
		},
		fileDownload(data, fileName) {
			let blob = new Blob([data], {
				type: "application/octet-stream"
			});
			let filename = fileName || "filename.xls";
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}
				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
			}
		},
		//批量导入
		importing(file, fileList) {
			var _this = this;
			if (file.response) {
				if (file.response.data) {
					_this.defeated(file.response.data.msg);
				}
			}
		},
	}
};
